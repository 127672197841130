<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="MDCN"
      title="MDCN"
      v-if="displayNav(this.$route.name)"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard'
          }"
        />

        <sidebar-item :link="{name: 'My Account', icon: 'ni ni-single-02 text-yellow', path: '/profile'}"/>
        <sidebar-item :link="{name: 'Change Password', icon: 'ni ni-settings-gear-65 text-red', path: '/change-password'}"/>
        <sidebar-item :link="{name: 'Professional Data', icon: 'ni ni-planet text-blue', path: '/professional-data'}"/>
        <sidebar-item :link="{name: 'Insurance Indemnity', icon: 'ni ni-credit-card text-orange', path: '/insurance-indemnity'}"/>
        <sidebar-item :link="{name: 'My Applications', icon: 'ni ni-folder-17 text-red', path: '/applications'}"/>
        <sidebar-item :link="{name: 'Renew License', icon: 'ni ni-spaceship text-yellow', path: '/my-license'}"/>
        <sidebar-item :link="{name: 'Other Payments', icon: 'ni ni-credit-card text-blue', path: '/other-payments'}"/>
        <sidebar-item :link="{name: 'My Payments', icon: 'ni ni-bullet-list-67 text-red', path: '/payments'}"/>
        <!-- <sidebar-item
          v-if="isOldDoctor"
          :link="{name: 'NMA Building Levy', icon: 'ni ni-shop text-blue', path: '/new-application/old-nma-levy'}"
        /> -->
        <!-- <sidebar-item
          v-if="! isOldDoctor"
          :link="{name: 'NMA Building Levy', icon: 'ni ni-shop text-blue', path: '/new-application/nma-levy'}"
        /> -->
        <sidebar-item 
          v-if="ticketing && ticketing.status"
          :link="{name: 'My Induction Tickets', icon: 'ni ni-bullet-list-67 text-red', path: '/induction-tickets'}"
        />
        <sidebar-item :link="{name: 'My Certificates', icon: 'ni ni-folder-17 text-info', path: '/certificates'}"/>
        <sidebar-item :link="{name: 'Logout', icon: 'ni ni-user-run text-pink', path: '/logout'}"/>

      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view :key="$route.fullPath" v-if="! hasNotPaidMwanLevy"></router-view>

          <!-- MWAN payment template included here -->
          <MwanPaymentTemplate 
            :loadApplication="fetchUserProfile"
            v-if="hasNotPaidMwanLevy"
          />

        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';
  import { mapActions, mapGetters } from 'vuex';
  import moment from 'moment';
  import MwanPaymentTemplate from '../views/MwanPaymentTemplate/MwanPaymentTemplate.vue';

  export default {
      name: 'DashboardLayout',
      components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition,
      MwanPaymentTemplate
    },
    data() {
      return {
        sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
      };
    },
    computed: {
      ...mapGetters(['basicProfile', 'user']),
      isOldDoctor() {
        let currentYear = moment([moment().year()]);
        let gradYear = this.basicProfile && this.basicProfile ? moment([parseInt(this.basicProfile.year_of_graduation)]) : false;
        return currentYear.diff(gradYear, 'years') >= 9;
      },
      ticketing() {// induction ticketing
        return this.basicProfile ? this.basicProfile.ticketing : {};
      },
      hasNotPaidMwanLevy() {// if doctor is medical women and has not paid levy
        const mwan = this.user && this.user.basic_profile && this.user.basic_profile.mwan_levy ? this.user.basic_profile.mwan_levy : false;
        return mwan && mwan.status && ! mwan.pay_status;
      }
    },
    methods: {
      ...mapActions(['fetchUserProfile']),
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
      displayNav(value) {
        let data = ['New Account Purchase Insurance', 'Update Profile', 'New Account', 'New Temporary Account'];
        // eslint-disable-next-line
        return ($.inArray(value, data)) < 0;
      }
    },
    mounted() {
      this.fetchUserProfile();// get user profile
    }
  };
</script>
<style lang="scss">
</style>
